<template>
  <div class="overview">
    <b-container class="main-gradient light" fluid>
      <b-row>
        <b-col no-gutters>
          <b-row class="chart-menu-container justify-content-center">
            <b-col md="auto"><b-link disabled>Menu 1</b-link></b-col>
            <b-col md="auto"><b-link to="#">Menu 2</b-link></b-col>
            <b-col md="auto"><b-link to="#">Menu 3</b-link></b-col>
            <b-col md="auto"><b-link to="#">Menu 4</b-link></b-col>
          </b-row>
          <b-row>
            <b-col class="chart-container">
                <incomes-and-expenses-chart
                  :dataObject="graph_data"
                  :primaryLabel="'Total income'"
                  :secondaryLabel="'Total expenses'"
                  :differenceLabel="'Difference'"
                  :birthYear="1981">
                  ></incomes-and-expenses-chart>
              </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>

    <b-container class="dashboad bg-light page-wrapper">
      <b-row class="main-content-wrapper" no-gutters>
        <b-col>

          <h1>D3Chart: "Incomes-and-expenses-chart"</h1>
          <p class="lead">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
            minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur.
          </p>

        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import IncomesAndExpensesChart from '@/components/d3charts/incomes-and-expenses/IncomesAndExpensesChart'

export default {
  name: 'Overview',
  data () {
    return {
      // This is the right data structure :)
      // This chart has stacked bars, but if you want only one bar, then there need to be only one item in the primaryDataset
      // This chart can have more than one line and area if you add more items to secondaryDataset
      graph_data: {
        primaryDataset: [
          {
            type: 'A',
            data: [
              { year: 2015, value: 12000 },
              { year: 2016, value: 11000 },
              { year: 2017, value: 14000 },
              { year: 2018, value: 15000 },
              { year: 2019, value: 18000 },
              { year: 2020, value: 13000 },
              { year: 2021, value: 17000 }
            ]
          },
          {
            type: 'B',
            data: [
              { year: 2015, value: 1000 },
              { year: 2016, value: 1000 },
              { year: 2017, value: 2000 },
              { year: 2018, value: 1000 },
              { year: 2019, value: 4000 },
              { year: 2020, value: 3000 },
              { year: 2021, value: 1000 }
            ]
          }
        ],
        secondaryDataset: [
          {
            type: 'C',
            data: [
              { year: 2015, value: 10000 },
              { year: 2016, value: 11000 },
              { year: 2017, value: 13000 },
              { year: 2018, value: 14000 },
              { year: 2019, value: 16000 },
              { year: 2020, value: 13000 },
              { year: 2021, value: 12000 }
            ]
          }
        ]
      }
    }
  },
  methods: {
  },
  computed: {
    breadcrumb_items () {
      return [{
        text: 'Incomes & Expenses',
        to: { name: 'overview' }
      },
      {
        text: 'Overview',
        active: true
      }]
    }
  },
  components: {
    IncomesAndExpensesChart
  }
}
</script>

<style lang="scss">
  .overview {
    .chart-container {
      border-top: 1px solid #404A65;
    }

    .chart-menu-container {
      padding-top: 120px;
      padding-bottom: 24px;

      a {
        opacity: 0.5;
        color: #FFFFFF;
        font-size: 24px;
        line-height: 32px;
        text-decoration: none;

        &.disabled {
          opacity: 1;
          font-weight: bold;
        }
      }
    }
  }
</style>
