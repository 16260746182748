<template>
  <div class="chart-wrapper">
    <svg :width="width"
         :height="height">
      <linearGradient id="lineGradient" gradientUnits="userSpaceOnUse"
                      x1="0%" y1="0%" x2="0%" y2="100%">
        <stop offset="0%"  stop-color="rgba(0, 101, 255, 0.8)" />
        <stop offset="100%" stop-color="rgba(87, 91, 122, 0)" />
      </linearGradient>
      <g :transform="`translate(${padding.left}, ${padding.top})`" class="chart-stack"></g>
      <g :transform="`translate(${padding.left}, ${padding.top})`" class="chart"></g>
      <line :x1="0"
            :x2="width"
            :y1="padding.top + chartHeight + hoverrectMehrYBottom"
            :y2="padding.top + chartHeight + hoverrectMehrYBottom"
            :stroke="divisionLineColor"
            stroke-width="0.5">
      </line>
      <g class="slider-wrapper"
         :transform="`translate(${sliderMargin}, ${padding.top + chartHeight + hoverrectMehrYBottom + 35})`"
      >
        <slider v-if="sliderWidth && staticDomain.length"
                :sliderWidth="sliderWidth"
                :staticDomain="staticDomain"
                :maximumSliderRange="maximumSliderRange"
                @dragged="onSliderDrag"
        />
      </g>
      <Tooltip :svgWidth="width"
               :svgHeight="height"
               :obj="tooltipObj"
               :handleOverflowX="true"
               :handleOverflowY="false">
      </Tooltip>
    </svg>
  </div>
</template>

<script>
import Slider from '../d3-assets/Slider'
import Tooltip from '../d3-assets/Tooltip'
import patternify from '../d3-assets/patternify'
export default {
  props: [
    'dataObject',
    'birthYear',
    'primaryLabel', // tooltip label that indicates bars and corresponds to primaryDataset property in dataObject
    'secondaryLabel', // tooltip label that indicates line and corresponds to secondaryDataset property in dataObject
    'differenceLabel'
  ],
  components: {
    Tooltip,
    Slider
  },
  data () {
    return {
      width: 0,
      height: 550,
      padding: {
        left: 110,
        right: 15,
        top: 35,
        bottom: 15
      },
      sliderHeight: 180,
      hoverrectMehrYBottom: 60,
      mehrHeight: 60,
      axisCircleSize: 4,
      capacityPercentage: 0.2,
      divisionLineColor: '#404A65',
      colors: {
        surplus: '#FEC600',
        shortfall: '#D90060'
      },
      barColors: [
        '#FEC600',
        '#EE00F7',
        '#00DEFB',
        '#F2006B',
        '#FFF0B2',
        '#FFBDAD',
        '#A5ADBA'
      ],
      circleColor: '#0065FF',
      darkColor: '#A5ADBA',
      domain: [],
      staticDomain: [],
      tooltipObj: {
        visible: false
      },
      svg: null,
      chart: null
    }
  },
  computed: {
    dataArray () {
      return this.dataObject.primaryDataset[0].data
    },
    tooltipData () {
      return this.domain.map(year => {
        let primarySum = 0
        let secondarySum = 0

        this.dataObject.primaryDataset.forEach(d => {
          const tmp = d.data.filter(x => x.year === year)
          if (tmp.length) {
            primarySum += tmp[0].value
          }
        })

        this.dataObject.secondaryDataset.forEach(d => {
          const tmp = d.data.filter(x => x.year === year)
          if (tmp.length) {
            secondarySum += tmp[0].value
          }
        })

        return { year, primarySum, secondarySum }
      })
    },
    computedData () {
      const cont = []
      for (let i = this.domain[0]; i <= this.domain[this.domain.length - 1]; i++) {
        const dt = {
          year: i
        }

        this.dataObject.primaryDataset.forEach(d => {
          const y = d.data.filter(x => x.year === i)
          dt[d.type] = y.length ? y[0].value : 0
        })

        cont.push(dt)
      }

      return cont
    },
    bars () {
      return this.$d3.stack()
        .order(this.$d3.stackOrderDescending)
        .keys(this.dataObject.primaryDataset.map(x => x.type))(this.computedData)
    },
    lines () {
      return this.dataObject.secondaryDataset
        .map(d => {
          var values = d.data.filter(x => this.domain.indexOf(x.year) > -1)
          return [
            values[0]
          ].concat(values)
        })
    },
    chartHeight () {
      return this.height - this.padding.top - this.padding.bottom - this.sliderHeight
    },
    chartWidth () {
      return this.width - this.padding.left - this.padding.right
    },
    maximumSliderRange () {
      return Math.round(this.dataArray.length / 2)
    },
    sliderWidth () {
      return this.width * 0.7 // 70% of whole width;
    },
    sliderMargin () {
      return this.width * 0.15 // 15% each side
    },
    xScale () {
      return this.$d3.scaleBand()
        .domain(this.domain)
        .range([0, this.chartWidth])
        .paddingInner([0.02])
        .paddingOuter([0.1])
    },
    yScale () {
      const min = this.$d3.min(this.bars, d => this.$d3.min(d, d => Math.min(d[0], d[1])))
      const max = this.$d3.max(this.bars, d => this.$d3.max(d, d => Math.max(d[0], d[1])))

      const maxExpense = this.$d3.max(this.lines, d => this.$d3.max(d, x => x.value))

      return this.$d3.scaleLinear()
        .domain([min, Math.max(maxExpense, max)])
        .range([this.chartHeight, 0])
    },
    lineGenerator () {
      return this.$d3.line()
        .x((d, i) => {
          if (i > 0) {
            return this.xScale(d.year) + this.xScale.bandwidth() / 2
          }
          return this.xScale(d.year)
        })
        .y(d => this.yScale(d.value))
    },
    areaGenerator () {
      return this.$d3.area()
        .x((d, i) => {
          if (i > 0) {
            return this.xScale(d.year) + this.xScale.bandwidth() / 2
          }
          return this.xScale(d.year)
        })
        .y0(this.yScale(this.yScale.domain()[0]))
        .y1(d => this.yScale(d.value))
    }
  },
  methods: {
    colorScale (i) {
      return this.barColors[i % this.barColors.length]
    },
    thousandsFormat (value) {
      const locale = this.$d3.formatLocale({
        decimal: ',',
        thousands: ' ',
        grouping: [3]
      })
      return locale.format(',')(value)
    },
    buildTooltipHtml (d, i) {
      return `<div class="toolTip">
                    <div class="year">
                      <strong><span>${d.year}</span></strong> ( age of <strong><span>${d.year - this.birthYear}</span></strong> )
                    </div>
                    <div class="d-flex justify-content-between mt-2">
                      <div class="mr-3">
                        <span class="tooltip-amount-span" style="background-color: #FAC604;"></span>
                        <span class="ml-2 label-span">${this.primaryLabel}:</span>
                      </div>
                      <div class="ml-4">
                        <span class="amount-span">${this.thousandsFormat(d.primarySum)}</span> SEK
                      </div>
                    </div>
                    <div class="d-flex justify-content-between mt-2">
                      <div class="mr-3">
                        <span class="tooltip-expense-span"></span>
                        <span class="ml-2 label-span">${this.secondaryLabel}:</span>
                      </div>
                      <div class="ml-4">
                        <span class="amount-span">${this.thousandsFormat(d.secondarySum)}</span> SEK
                      </div>
                    </div>
                    <hr class="tooltip-hr">
                    <div class="d-flex justify-content-between mt-2">
                      <div class="mr-3">
                        <span class="tooltip-amount-span" style="background-color: #D90060;"></span>
                        <span class="ml-2 label-span">${this.differenceLabel}:</span>
                      </div>
                      <div class="ml-4">
                        <span class="amount-span">${this.thousandsFormat(d.primarySum - d.secondarySum)}</span> SEK
                      </div>
                    </div>
                  </div>`
    },
    rectmouseover (d, i) {
      const html = this.buildTooltipHtml(d, i)

      const x = this.xScale(d.year) + this.xScale.bandwidth() / 2 + this.padding.left
      const y = this.padding.top * 1.5

      this.tooltipObj = {
        x: x,
        y: y,
        html: html,
        visible: true
      }

      // current tick selection
      const xTick = this.chart
        .select(`#xTick-${d.year}`)

      // make circle bigger and filled white
      xTick.select('circle')
        .attr('fill', '#fff')
        .attr('stroke', '#1971ff')
        .attr('r', this.axisCircleSize + 2)

      // display hover rect
      this.chart
        .select('#hoverrect')
        .attr('x', this.xScale(d.year))
        .style('display', 'block')

      // make texts a little bit bigger
      xTick.selectAll('text')
        .attr('fill', '#fff')
        .attr('font-weight', 600)
    },
    rectmouseout (d, i) {
      // current tick selection
      const xTick = this.chart.select(`#xTick-${d.year}`)

      // make circle smaller
      xTick.select('circle')
        .attr('fill', '#1971ff')
        .attr('stroke', '#fff')
        .attr('r', this.axisCircleSize)

      // hide hover rect
      this.chart
        .select('#hoverrect').style('display', 'none')

      // make texts smaller
      xTick.selectAll('text')
        .attr('fill', this.darkColor).attr('font-weight', 400)

      // hide tooltip
      this.tooltipObj.visible = false
    },
    calcOpacity (d, i) {
      const length = this.domain[this.domain.length - 1] - this.domain[0]
      const l = Math.round(length * this.capacityPercentage)
      const h = length - l

      const sc = this.$d3.scaleLinear().clamp(true)

      if (i <= l) {
        sc.domain([0, l]).range([0.25, 1])
        return sc(i)
      }
      if (i >= h) {
        sc.domain([h, this.domain.length - 1]).range([1, 0.25])
        return sc(i)
      }
      return 1
    },
    drawAxis () {
      const tickSize = this.chartHeight
      const xAxis = this.$d3.axisBottom(this.xScale).tickSize(-(tickSize))
      const yAxis = this.$d3.axisLeft(this.yScale).ticks(5).tickFormat(d => this.thousandsFormat(d) + ' Kr')

      // append xAxis
      patternify({
        container: this.chart,
        tag: 'g',
        selector: 'xAxis'
      })
        .attr('class', 'xAxis axis')
        .attr('transform', `translate(${0}, ${this.chartHeight})`)
        .call(xAxis)

      // append yAxis
      patternify({
        container: this.chart,
        tag: 'g',
        selector: 'yAxis'
      })
        .attr('class', 'yAxis axis')
        .call(yAxis)
    },
    drawChart () {
      // bars
      const stacks = patternify({
        tag: 'g',
        selector: 'stack-group',
        container: this.chartStack,
        data: this.bars
      })
        .attr('fill', d => this.colorScale(d.index))

      patternify({
        tag: 'rect',
        selector: 'stack-rect',
        container: stacks,
        data: d => d
      })
        .attr('height', d => this.yScale(Math.min(d[0], d[1])) - this.yScale(Math.max(d[0], d[1])))
        .attr('width', this.xScale.bandwidth())
        .attr('x', d => this.xScale(d.data.year))
        .attr('y', d => this.yScale(Math.max(d[0], d[1])))
        .attr('opacity', (d, i) => this.calcOpacity(d, i))

      // draw area
      patternify({
        tag: 'path',
        selector: 'area',
        container: this.chart,
        data: this.lines
      })
        .attr('d', this.areaGenerator)
        .attr('fill', 'url(#lineGradient)')

      // draw line
      patternify({
        tag: 'path',
        selector: 'line',
        container: this.chart,
        data: this.lines
      })
        .attr('d', this.lineGenerator)
        .attr('fill', 'none')
        .attr('stroke', '#fff')
        .attr('stroke-width', 2)

      // hidden rectangles
      patternify({
        tag: 'rect',
        selector: 'hiddenrect',
        container: this.chart,
        data: this.tooltipData
      })
        .attr('x', d => this.xScale(d.year))
        .attr('y', 0)
        .attr('width', d => this.xScale.bandwidth())
        .attr('height', d => this.chartHeight + this.hoverrectMehrYBottom)
        .attr('data-year', d => d.year)
        .attr('opacity', (d, i) => this.calcOpacity(d, i))
        .on('mouseover', (d, i) => this.rectmouseover(d, i))
        .on('mouseout', (d, i) => this.rectmouseout(d, i))

      // hover rectangle
      patternify({
        tag: 'rect',
        selector: 'hoverrect',
        container: this.chart
      })
        .attr('id', 'hoverrect')
        .attr('opacity', 0.35)
        .attr('fill', 'black')
        .attr('x', 0)
        .attr('y', -this.padding.top)
        .attr('width', d => this.xScale.bandwidth())
        .attr('height', d => this.chartHeight + this.padding.top + this.hoverrectMehrYBottom)
    },
    adjustAxis () {
      const self = this
      const ticks = this.chart.select('g.xAxis')
        .selectAll('.tick')
        .attr('data-age', (d, i) => {
          const year = this.xScale.domain()[0] + i
          return year - this.birthYear
        })
        .attr('id', (d, i) => {
          const year = this.xScale.domain()[0] + i
          return `xTick-${year}`
        })
        .attr('opacity', (d, i) => {
          return this.calcOpacity(d, i)
        })

      ticks.select('line')
        .attr('stroke', this.darkColor)
        .attr('stroke-width', 0.5)
        .attr('stroke-dasharray', '3 1.5')

      // reposition texts within the axis
      ticks.select('text')
        .attr('class', 'tick-year')
        .attr('dy', '4em')
        .attr('fill', this.darkColor)
        .attr('font-family', 'Roboto')

      // append circles
      patternify({
        container: ticks,
        tag: 'circle',
        selector: 'tickCircle'
      })
        .attr('r', this.axisCircleSize)
        .attr('fill', this.circleColor)
        .attr('stroke', '#fff')
        .attr('stroke-width', 1)

      // append ages
      patternify({
        container: ticks,
        tag: 'text',
        selector: 'secondaryAxisText'
      })
        .attr('class', 'secondaryAxisText')
        .attr('dy', '2.5em')
        .attr('font-family', 'Roboto')
        .text(function (d, i) {
          const tick = self.$d3.select(this.parentElement)
          return tick.attr('data-age')
        })

      // adjust xAxis
      const yTicks = this.chart.select('g.yAxis')
        .selectAll('.tick')

      // reposition lines
      yTicks.select('line')
        .attr('x1', (d, i) => {
          if (i) {
            return -7
          }
          return 0
        })
        .attr('x2', (d, i) => {
          if (i) {
            if (d === 0) {
              return this.chartWidth
            }
            return 4
          }
          return 0
        })
        .attr('stroke', d => {
          if (d === 0) {
            return '#ffffff'
          }
          return '#A5ADBA'
        })
        .attr('stroke-width', 1.5)

      yTicks.select('text')
        .attr('x', -24)
        .attr('font-family', 'Roboto')

      // append circles
      patternify({
        container: yTicks,
        tag: 'circle',
        selector: 'tickCircle'
      })
        .attr('r', this.axisCircleSize)
        .attr('cx', -10)
        .attr('fill', '#6D7290')
        .attr('stroke', '#ffffff')
        .attr('stroke-width', 1)
    },
    setWidth () {
      this.width = this.$el.offsetWidth
    },
    onResize () {
      this.setWidth()
      this.setDomain()
      this.draw()
    },
    setDomain () {
      const min = this.$d3.min(this.dataArray, d => d.year)
      const max = this.$d3.max(this.dataArray, d => d.year)
      this.staticDomain = this.$d3.range(min, max + 1)
      if (!this.domain.length) {
        this.domain = this.$d3.range(min, min + this.maximumSliderRange + 1)
      }
    },
    onSliderDrag (domain) {
      this.domain = domain
      this.draw()
    },
    draw () {
      this.drawChart()
      this.drawAxis()
      this.adjustAxis()
    }
  },
  mounted () {
    this.svg = this.$d3.select(this.$el).select('svg')
    this.chart = this.svg.select('g.chart')
    this.chartStack = this.svg.select('g.chart-stack')
    this.$d3.select(window).on('resize', this.onResize)
    this.onResize()
  }
}
</script>

<style lang="scss">
  @import './IncomesAndExpensesChart.scss';
</style>
